<template>
<div>
<div class="tg_menu_ber_boolong_nsx">
  <div class="container">
    <ul>
      <li><a @click="redirectToCalendar()">Booking calendar</a></li>
      <li><a href="javascript:void(0)">Rental fee</a></li>
    </ul>
  </div>
</div>

<div class="container">
  <div class="tg_rental_fee_nsx">
      
    <div class="table_ver1_rental_fee">
      <h3>ホールレンタル</h3>
      <table>
        <thead>
        <tr>
          <th>項目</th>
          <th>価格/時間</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in listRental.room" :key="index">
          <td>{{item.name}}</td>
          <td class="text-right">{{item.price_format}}円</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="table_ver2_rental_fee">
      <h3>スタッフ、機材</h3>
      <table>
        <thead>
        <tr>
          <th>カテゴリー</th>
          <th>項目</th>
          <th>価格/回</th>
          <th style="min-width: 100px;text-align: center">数量</th>
          <th style="min-width: 80px;"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(each,index) in listRental.listEquipment" :key="index">
          <td>{{each.category.name}}</td>
          <td>{{each.name}}</td>
          <td class="text-right">{{each.price_format}}円</td>
          <td style="text-align: center;">{{each.quantity}}</td>
          <td><a v-if="each.description.length > 2" @click.prevent="showModal(each.id)" class="button-show-popup">詳細</a></td>
        </tr>
        </tbody>
      </table>
      <router-link class="bt_click_rental_fee" :to="{ name: 'calendar'}">戻る</router-link>
    </div>
  </div>
</div>
  <!-- modal room -->
  <div id="detail-equipment" class="popup_bos18" v-bind:class="{'popup':check}">
    <div class="content_popup">
      <h2 class="popup_title">{{title}}</h2>
      <div class="form_group">
        <table>
          <thead>
          <tr>
            <th>機材名</th>
            <th>数量</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(arr, index) in arrayDes" :key="index">
            <td style="text-align: left">{{arr.equipment_name}}</td>
            <td>{{arr.equipment_quantity}}</td>
          </tr>
          </tbody>
        </table>
        <button @click="closeModal()" type="submit">閉じる</button>
      </div>
    </div>
  </div>

</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import {
GET_LIST_RENTAL,
GET_LIST_CATEGORY,
GET_DETAIL_EQUIPMENT,
} from "@/core/services/store/rental.module";
import * as constants from "@/core/config/constant";

export default {
name: "list-rental",
data() {
  return {
    data: {},
    arrayDes: [
      {
        equipment_name: '',
        equipment_quantity: '',
      }
    ],
    title: '',
    id_equiqment: '',
    check: false,
  };
},
computed: {
  ...mapGetters(["listRental", "listCategory", "detailEquipment"]),
  queryParam() {
    return this.$route.query;
  },
},

watch: {
  async queryParam() {
    await this.getListRental();
  },
},
async created() {
  await this.getListRental();
  await this.getListCategory();
},
methods: {
  ...mapActions([GET_LIST_RENTAL, GET_LIST_CATEGORY, GET_DETAIL_EQUIPMENT]),
  getEquipment(){
    this.arrayDes = this.detailEquipment.description ?? '';
    this.title = this.detailEquipment.name;
  },
  async showModal(id){
    await this.getDetailEquipment(id);
    this.getEquipment();
    this.check = true
  },
  closeModal(){
    this.check = false
  },
  redirectToCalendar() {
    this.$router.push({ name: 'calendar'})
  }
},
};
</script>

<style lang="scss" scope>
.custom_row{
padding-top: 50px;
}
.btn.btn-secondary {
color: #fff;
background-color: #3699ff;
border-color: hsl(210, 100%, 61%);
}
div#add-equipment .modal-dialog.modal-md.modal-dialog-centered {
min-width: 60% !important;
}
.custom-label{
background-color: #3699ff;
color:#fff;
}
.el-form-item.text-center.custom-label,.el-form-item.custom-input {
margin-left: 16%;
}
.button-show-popup {
cursor: pointer;
color:#3699ff;
}
button.btn.btn-back {
color: #3F4254;
background-color: #d7dae7;
border-color: #d7dae7;
}
.custom-el-table{
background-color: #3699ff;

}
.custom_table th.is-leaf.el-table__cell {
background: #3699ff;
text-align: center;
color: #fff;
}
.cell {
text-align: center;
}
td.el-table_2_column_3.el-table__cell .cell, td.el-table_2_column_4.el-table__cell .cell {
text-align: left;
}
.fade {
opacity: 1 !important;
}
th.is-leaf.el-table__cell{
background-color: #328fd2;
color: #fff;
}
.popup_bos18{
display: none;
}
body {
background-color: #fff;
}
.popup.popup_bos18 button {
  font-size: 16px;
}
.text-right {
  text-align: right !important;
}
</style>
